import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { TrendingPage } from '@/components/HomePageTrending/TrendingPage';
import {
  fetchChainDataSummary,
  fetchChainDataSummaryGraphs,
} from '@/components/HomePageTrending/utils/fetchChainsDataSummary';
import { DataSummaryVolume } from '@/components/HomePageTrending/utils/fetchChainsDataSummary';
import { defaultChainDataSummary } from '@/components/HomePageTrending/utils/fetchChainsDataSummary';
import { getSortedCategoriesByVolume } from '@/components/HomePageTrending/utils/getSortedCategoriesByVolume';
import { Layout } from '@/components/layout/Layout';
import { fetchTopTrendingTokensFromAPI } from '@/helpers/utils/fetchTrendingTokens';
import { CoinCategory } from '@/helpers/utils/tokenExplorer/FilterCategories';
import { EDataSummaryType } from '@/services/index';
import { ChainDataSummaryDto } from '@/services/models/ChainDataSummaryDto';
import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';
import { fetchContentfulAdsData } from '@/utils/contentful/fetchAds';
import { fetchContentfulGlossaryData } from '@/utils/contentful/fetchGlossary';
import { Advertisement, TGlossaryItem, TGlossarySection } from '@/utils/contentful/type';
import { handleSettledPromiseResult } from '@/utils/handleSettledPromiseResult';
import { logApp } from '@/utils/logApp';
import { getFullURLByContext } from '@/utils/nextjs/url';
import { readCache, writeCache } from '@/utils/redis/redisCache';
import { CACHE_KEY } from '@/utils/redis/redisCacheKeys';
import { CACHE_TTL } from '@/utils/redis/redisCacheTTL';

const log = logApp.create('useTrendingTokens HP');

const HomePage = ({
  trendingTokenData,
  pageInfo,
  fullUrl,
  glossaryMoneyMetricTerms,
  ads,
  sortedCategoriesByVolume,
  dataSummaryTokens,
  dataSummaryTotalVolume,
  dataSummaryNetVolume,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <Layout
      hasFooter={true}
      hasHeader={true}
      hasSearchBar={false}
      hasTwitterBanner={false}
      layout="tokenExp"
      metaDataTags={{
        title: pageInfo.title,
        description: pageInfo.description,
        url: fullUrl,
      }}
      noPadding={true}
      screenWidth="full"
    >
      <TrendingPage
        ads={ads}
        trendingTokenData={trendingTokenData}
        chainId={null}
        category={null}
        glossaryMoneyMetricTerms={glossaryMoneyMetricTerms}
        sortedCategories={sortedCategoriesByVolume}
        dataSummaryTokens={dataSummaryTokens}
        dataSummaryTotalVolume={dataSummaryTotalVolume}
        dataSummaryNetVolume={dataSummaryNetVolume}
      />
    </Layout>
  );
};

const defaultPageInfo: { title: string; description: string } = {
  title: 'Trending Crypto Tokens by On-Chain Data | Moralis',
  description:
    'Discover trending crypto tokens based on real-time on-chain data across multiple chains. Stay ahead by knowing which crypto tokens are currently trending.',
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const chainIdForCacheKey = getStringForCacheKey(null);
  const categoryForCacheKey = getStringForCacheKey(null);

  const tokensPromise = (async () => {
    const cacheKey = CACHE_KEY.trendingTokens({ category: categoryForCacheKey, chainId: chainIdForCacheKey });
    const cachedTokens = await readCache<{ coins: TrendingCoinInfoDto[]; title: string; description: string }>(
      cacheKey,
    );
    if (cachedTokens) {
      return cachedTokens;
    }

    try {
      const result = await fetchTopTrendingTokensFromAPI(context);
      const tokensData = {
        coins: result.tokens.coins || [],
        title: result.tokens.title || defaultPageInfo.title,
        description: result.tokens.description || defaultPageInfo.description,
      };

      writeCache({
        key: cacheKey,
        data: tokensData,
        ttl: CACHE_TTL.THREE_MINUTES,
      });

      return tokensData;
    } catch {
      return {
        coins: [],
        title: defaultPageInfo.title,
        description: defaultPageInfo.description,
      };
    }
  })();

  const glossaryPromise = (async () => {
    const cachedGlossary = await readCache<TGlossarySection[]>(CACHE_KEY.glossaryMoneyMetricTerms);
    if (cachedGlossary) {
      return cachedGlossary;
    }

    try {
      const glossaryData = await fetchContentfulGlossaryData('moralis-money-metrics');
      const glossaryTerms =
        glossaryData?.glossary?.sectionsCollection?.items.flatMap(
          (section: TGlossarySection) => section.termsCollection?.items || [],
        ) ?? [];

      writeCache({ key: CACHE_KEY.glossaryMoneyMetricTerms, data: glossaryTerms, ttl: CACHE_TTL.ONE_DAY });

      return glossaryTerms;
    } catch (error) {
      log.error('Failed to load glossary data HP', error);
      return [];
    }
  })();

  const adsPromise = fetchContentfulAdsData(context);

  const sortedCategoriesByVolumePromise = (async () => {
    const cacheKey = CACHE_KEY.sortedCategoriesByVolume({ chainId: chainIdForCacheKey });
    const cachedCategories = await readCache(cacheKey);

    if (Array.isArray(cachedCategories)) {
      return cachedCategories;
    }

    try {
      const categories = await getSortedCategoriesByVolume(context);

      writeCache({
        key: cacheKey,
        data: categories,
        ttl: CACHE_TTL.ONE_HOUR,
      }).catch((error) => {
        log.error('Failed to save sorted categories data to the cache', error);
      });

      return categories;
    } catch (error) {
      log.error('Failed to load sorted categories from API', error);
      return [];
    }
  })();

  const dataSummaryTokensPromise = (async (): Promise<ChainDataSummaryDto> => {
    const cacheKey = CACHE_KEY.dataSummaryTokens({ category: categoryForCacheKey, chainId: chainIdForCacheKey });
    const cachedDataSummary = await readCache<ChainDataSummaryDto>(cacheKey);

    if (cachedDataSummary) {
      return cachedDataSummary;
    }

    try {
      const dataSummary = await fetchChainDataSummary({ chainId: null, category: null }, context);

      if (!dataSummary || !dataSummary.chainIds || dataSummary.chainIds.length === 0) {
        return defaultChainDataSummary;
      }

      writeCache({
        key: cacheKey,
        data: dataSummary,
        ttl: CACHE_TTL.ONE_HOUR,
      }).catch((error) => {
        log.error('Failed to fetch data summary', error);
      });

      return dataSummary;
    } catch (error) {
      log.error('Failed to fetch data summary tokens', error);
      return defaultChainDataSummary;
    }
  })();

  const dataSummaryTotalVolumePromise = (async (): Promise<DataSummaryVolume[]> => {
    const cacheKey = CACHE_KEY.dataSummaryTotalVolume({ category: categoryForCacheKey, chainId: chainIdForCacheKey });
    const cachedDataSummary = await readCache<DataSummaryVolume[]>(cacheKey);

    if (cachedDataSummary) {
      return cachedDataSummary;
    }

    try {
      const dataSummaryTotalVolume = await fetchChainDataSummaryGraphs<DataSummaryVolume[]>(
        { chainId: null, category: null },
        EDataSummaryType.TOTAL_VOLUME,
        context,
      );

      if (!dataSummaryTotalVolume || dataSummaryTotalVolume.length === 0) {
        return [];
      }

      writeCache({
        key: cacheKey,
        data: dataSummaryTotalVolume,
        ttl: CACHE_TTL.THREE_HOURS,
      }).catch((error) => {
        log.error('Failed to cache data summary total volume', error);
      });

      return dataSummaryTotalVolume;
    } catch (error) {
      log.error('Failed to fetch data summary total volume', error);
      return [];
    }
  })();

  const dataSummaryNetVolumePromise = (async (): Promise<DataSummaryVolume[]> => {
    const cacheKey = CACHE_KEY.dataSummaryNetVolume({ category: categoryForCacheKey, chainId: chainIdForCacheKey });
    const cachedDataSummary = await readCache<DataSummaryVolume[]>(cacheKey);

    if (cachedDataSummary) {
      return cachedDataSummary;
    }

    try {
      const dataSummaryNetVolume = await fetchChainDataSummaryGraphs<DataSummaryVolume[]>(
        { chainId: null, category: null },
        EDataSummaryType.NET_VOLUME,
        context,
      );

      if (!dataSummaryNetVolume || dataSummaryNetVolume.length === 0) {
        return [];
      }

      writeCache({
        key: cacheKey,
        data: dataSummaryNetVolume,
        ttl: CACHE_TTL.THREE_HOURS,
      }).catch((error) => {
        log.error('Failed to cache data summary net volume', error);
      });

      return dataSummaryNetVolume;
    } catch (error) {
      log.error('Failed to fetch data summary net volume', error);
      return [];
    }
  })();

  const [
    tokensResult,
    glossaryResult,
    adsResult,
    categoriesResult,
    dataSummaryTokensResult,
    dataSummaryTotalVolumeResult,
    dataSummaryNetVolumeResult,
  ] = await Promise.allSettled([
    tokensPromise,
    glossaryPromise,
    adsPromise,
    sortedCategoriesByVolumePromise,
    dataSummaryTokensPromise,
    dataSummaryTotalVolumePromise,
    dataSummaryNetVolumePromise,
  ]);

  const tokens = handleSettledPromiseResult<{
    coins: TrendingCoinInfoDto[];
    title: string;
    description: string;
  }>(tokensResult, 'Tokens') ?? { coins: [], title: '', description: '' };
  const glossaryMoneyMetricTerms: TGlossaryItem[] = handleSettledPromiseResult(glossaryResult, 'Glossary') || [];
  const ads: Advertisement[] = handleSettledPromiseResult(adsResult, 'Ads') || [];
  const sortedCategoriesByVolume: (CoinCategory & { oneDayVolumeUsd: string })[] =
    handleSettledPromiseResult(categoriesResult, 'Sorted Categories') || [];
  const dataSummaryTokens = handleSettledPromiseResult<ChainDataSummaryDto>(
    dataSummaryTokensResult,
    'Data Summary Tokens Info',
  ) ?? { chainIds: [] };
  const dataSummaryTotalVolume =
    handleSettledPromiseResult(dataSummaryTotalVolumeResult, 'Data Summary Total Volume') || [];
  const dataSummaryNetVolume = handleSettledPromiseResult(dataSummaryNetVolumeResult, 'Data Summary Net Volume') || [];

  return {
    props: {
      trendingTokenData: Array.isArray(tokens) ? [] : tokens.coins,
      pageInfo: Array.isArray(tokens)
        ? { title: defaultPageInfo.title, description: defaultPageInfo.description }
        : { title: tokens.title, description: tokens.description },
      fullUrl: getFullURLByContext(context),
      glossaryMoneyMetricTerms,
      ads,
      sortedCategoriesByVolume,
      dataSummaryTokens,
      dataSummaryTotalVolume,
      dataSummaryNetVolume,
    },
  };
}

// Helper functions to ensure category and chainId are strings
export function getStringForCacheKey(value: { id?: string } | string | null): string {
  if (typeof value === 'string') {
    return value ?? 'null';
  }
  return value?.id ?? 'null';
}

export default HomePage;
